import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Breadcrumbs from "../../modules/breadcrumbs"
import Layout from "../../modules/layout"
import Categories from "../../components/categories"
import SimpleHero from "../../modules/simpleHero"
import Grid from "../../modules/grid"
import Pagination from "../../components/pagination"

export const query = graphql`
  query($slug: String, $skip: Int, $limit: Int) {
    sanitySiteConfig {
      url
    }
    sanityCategory(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
    }
    allBlogCats: allSanityBlog {
      distinct(field: category___slug___current)
    }
    allSanityBlog(
      sort: { fields: publishedAt, order: DESC }
      filter: { category: { slug: { current: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          _type
          title
          slug {
            current
          }
          author {
            name
          }
          excerpt
          category {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`

const CategoryPage = props => {
  const { data, location } = props
  const { currentPage, numPages, slug } = props.pageContext
  const title = `The PeopleFluent Blog | ${data.sanityCategory.title}`
  const description = null
  const image = null
  const canonical = `${data.sanitySiteConfig.url}/blog/${data.sanityCategory.slug.current}/`
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <SEO title={title} description={description} image={image} />
      <Breadcrumbs location={location}>
        <Link to="/">Home</Link>
        <Link to="/blog/">Blog</Link>
        <p>{data.sanityCategory.title}</p>
      </Breadcrumbs>
      <SimpleHero heading="The PeopleFluent Blog" />
      <section className="blocksMenuGrid">
        <Categories
          postType="blog"
          activeCat={slug}
          first="true"
          usedCats={data?.allBlogCats}
        />
        {data.allSanityBlog.edges.length && (
          <Grid blocks={data.allSanityBlog.edges} />
        )}
        {numPages > 1 && (
          <Pagination
            type={`/blog/${slug}`}
            currentPage={currentPage}
            totalPages={numPages}
          />
        )}
      </section>
    </Layout>
  )
}

export default CategoryPage
